<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialogDevolucao" max-width="700" persistent scrollable>
			<v-card>
				<v-card-title>Devolução de Concreto</v-card-title>
				<v-divider />
				<v-card-text class="px-6 pt-6">
          <v-alert
              text
              color="primary"
              icon="mdi-information"
              class="my-4 mt-0 pb-0"
          >
            <p style="font-size: 0.8em">
              Essa ação resultara na devolução totais dos produtos!
            </p>
          </v-alert>
					<v-simple-table dense dark class="primary">
						<thead>
							<tr>
								<th>Produto</th>
								<th class="text-center text-truncate">Quantidade</th>
                <th>Valor</th>
							</tr>
						</thead>
						<tbody v-if="produtos.total > 0">
							<tr v-for="(u,i) in produtos.lista" :key="i">
								<td>{{u.idsubproduto}} - {{u.produto}}</td>
								<td class="text-center">{{u.quantidade}}</td>
                <td class="text-left">{{u.valortotalvenda |formataDinheiro}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td class="text-center" colspan="3">Nenhum produto encontrado!</td>
							</tr>
						</tbody>
            <tfoot v-if="produtos.total > 0">
            <tr>
              <td>Total:</td>
              <td></td>
              <td class="px-4">{{produtos.lista.map(v => v.valortotalvenda).reduce((a,v) => a + v) | formataDinheiro}}</td>
            </tr>
            </tfoot>
					</v-simple-table>
				</v-card-text>
				<v-divider />
        <v-card-text class="py-4">
          <v-text-field
              v-model="motivo"
              label="Motivo"
              placeholder="Informe o motivo da devolução"
              :rules="caracteresRules"
              outlined
              dense
              autofocus
              class="mb-n4"
          >
          </v-text-field>
        </v-card-text>
				<v-card-actions class="pb-3 pt-0 px-6">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialogDevolucao = false"
					>Cancelar</v-btn>
					<v-btn
						:disabled="motivo.length <= 10"
						:loading="carregando"
						prefix="M"
						color="primary"
						elevation="0"
						@click="confirmaDevolucao()"
					>Confirmar devolução</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" lg="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">Nº Pedido:</span>
						{{dados.idpedido}}
					</div>
					<div class="my-3" v-if="dados.cliente">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Cliente:</span>
						{{dados.cliente}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.idempresa}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-calendar</v-icon>
						<span class="font-weight-bold">Data do pedido:</span>
						{{dados.dtcadastro}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-cash</v-icon>
						<span class="font-weight-bold">Valor:</span>
						{{dados.valortotal | formataDinheiro}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-note-text-outline</v-icon>
						<span class="font-weight-bold">Observação:</span>
						{{dados.observacao}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-octagon</v-icon>
						<span class="font-weight-bold mr-3">Status:</span>
						{{dados.status}}
					</div>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn
						v-if="permissao('concreto_devolver') && dados.status != 'DEVOLUCAO'"
						color="error"
						elevation="0"
						@click="listaProdutos(), dialogDevolucao = true"
					>Devolver</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12" lg="8">
			<v-row>
				<v-col cols="12">
					<Table
						:pgLimitGet="10"
						backendDirectory="concreto/pedido/produto/listar"
						:busca="{
                idpedido: parseInt(idpedido),
            }"
						:filter="false"
						:headers="{
                idsubproduto: {nome: 'ID', tipo: 'texto'},
                produto: {nome: 'Produto', tipo: 'texto'},
                quantidade: {nome: 'Quantidade', tipo: 'texto', class: 'text-center'},
                saldo: {nome: 'Saldo', tipo: 'texto', class: 'text-center'},
                valortotalvenda: {nome: 'Valor total', tipo: 'dinheiro', class: 'text-center text-truncate'},
                vendedor: {nome: 'Vendedor', tipo: 'texto'},
            }"
					>
						<v-col>Produtos</v-col>
					</Table>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>Dados financeiros</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-simple-table>
								<thead>
									<tr>
										<th>Título</th>
										<th>Dígito</th>
										<th>Valor</th>
										<th>Saldo</th>
										<th>Vencimento</th>
										<th>Pagamento</th>
										<th>Forma de pagamento</th>
										<th>Prazo de pagamento</th>
									</tr>
								</thead>
								<tbody v-if="recebimento">
									<tr v-for="(u,i) in recebimento" :key="i">
										<td>{{u.idtitulo}}</td>
										<td>{{u.digito}}</td>
										<td>{{u.valtitulo | formataDinheiro}}</td>
										<td>{{u.saldo | formataDinheiro}}</td>
										<td>{{u.dtvencimento}}</td>
										<td>{{u.dtultimopagamento}}</td>
										<td>{{u.recebimento}}</td>
										<td class="text-center">{{u.diasprazo}} dias</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="8" class="text-center">Nenhum registro encontrado.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>Processos</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-simple-table>
								<thead>
									<tr>
										<th>ID</th>
										<th>Processo</th>
										<th>Responsável</th>
										<th class="text-center">Status</th>
									</tr>
								</thead>
								<tbody v-if="processos.total > 0">
									<tr v-for="(u,i) in processos.lista" :key="i">
										<td>{{u.idprocesso}}</td>
										<td>{{u.processo}}</td>
										<td>{{u.responsavel}}</td>
										<td class="text-center">
											<v-chip small class="font-weight-bold">{{u.status}}</v-chip>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="4" class="text-center">Nenhum registro encontrado.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>Movimentações</v-card-title>
						<v-divider />
						<v-card-text class="pt-0">
							<v-simple-table>
								<thead>
									<tr>
										<th>ID</th>
                    <th>Produto</th>
                    <th class="text-center">Quantidade</th>
                    <th class="left">Data</th>
                    <th class="text-center ">Responsável</th>
                    <th class="text-center">Tipo</th>
									</tr>
								</thead>
								<tbody v-if="movimentacoes.total > 0">
									<tr v-for="(u,i) in movimentacoes.lista" :key="i">
										<td nowrap>{{u.idmovimentacao}}</td>
                    <td>
                      <v-tooltip open-delay="200" right color="primary" content-class='custom-tooltip'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{u.idsubproduto}} - {{u.produto | formataTextoPedidoConcreto}}</span>
                        </template>
                        <span>{{u.idsubproduto}} - {{u.produto}}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">{{u.quantidade}}</td>
										<td nowrap class="text-center">{{u.datamovimento}}</td>
										<td class="text-center">{{u.responsavel}}</td>
                    <td class="text-center">
                      <v-chip small class="font-weight-bold">{{u.tipo}}</v-chip>
                    </td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="5" class="text-center">Nenhum registro encontrado.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import Table from "../Widgets/Table";
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	components: { Table, CardDialog },
	name: "ConcretoPedidoGet",
	props: ["idpedido"],
	data: () => ({
		dialogDevolucao: false,
		carregando: false,
		dados: {},
		contratos: {},
		produtos: {},
		processos:{},
		movimentacoes: {},
    recebimento: {},
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		pedidoDev: { produtos: [] },
    motivo: '',
    caracteresRules: [
      value => !!value || 'Este campo é obrigatório.',
      value => value.length > 10 || 'O motivo deve ter mais que 10 caracteres'
    ]
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		// Função lista todas as informações referente ao pedido
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/get`, {
					idpedido: parseInt(this.idpedido),
				})
				.then((res) => {
					this.dados = res.data[0];
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
    // Função lista produtos referente ao pedido
    listaProdutos() {
      return axios
          .post(`${this.backendUrl}concreto/pedido/produto/listar`, {
            idpedido: parseInt(this.idpedido),
          })
          .then((res) => {
            this.produtos = res.data;
          })
          .catch(() => {
            this.produtos = {};
          });
    },
    // Função lista produtos referente ao pedido
    listaFinanceiro() {
      return axios
          .post(`${this.backendUrl}concreto/pedido/recebimento/listar`, {
            idpedido: parseInt(this.idpedido),
          })
          .then((res) => {
            this.recebimento = res.data.lista;
          })
          .catch(() => {
            this.recebimento = {};
          });
    },
		// Função realiza uma requisição de devolução de produtos não agendados
		confirmaDevolucao() {
			return axios
				.post(`${this.backendUrl}concreto/pedido/devolver`, {
					idpedido: parseInt(this.idpedido),
          hash: this.idpedido + ' - 0',
          idfilial: parseInt(this.dados.idempresa),
          observacao: this.motivo,
				})
				.then((res) => {
					if (res.data) {
						this.get();
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg = "Devolução efetuada com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
            this.dialogDevolucao = false;
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível efetuar a devolução do pedido!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível efetuar a devolução do pedido!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		// Função lista todas as informações referente aos processos do pedido
		listaProcessos() {
			return axios
				.post(`${this.backendUrl}concreto/pedido/processo/listar`, {
					idpedido: parseInt(this.idpedido),
				})
				.then((res) => {
					this.processos = res.data;
				})
				.catch(() => {
					this.processos = {};
				});
		},
		// Função lista todas as informações referente as movimentações do pedido
		listaMovimentacoes() {
			return axios
				.post(`${this.backendUrl}concreto/pedido/movimentacoes/listar`,{
						idpedido: parseInt(this.idpedido),
					}
				)
				.then((res) => {
          this.movimentacoes = res.data
				})
				.catch(() => {
					this.movimentacoes = {};
				});
		},
		// Valida se a devolução tem produtos e se todos tem quantidade maior que zero, validação inativa por enquanto
		// validaDevolucao() {
		// 	if (this.pedidoDev.produtos.length === 0) {
		// 		return true;
		// 	}
    //
		// 	const totalQuantidadeDev = this.pedidoDev.produtos.reduce(
		// 		(total, produto) => total + produto.quantidadedevolver,
		// 		0
		// 	);
    //
		// 	const maior = this.pedidoDev.produtos.some(
		// 		(v) => v.quantidadedevolver > v.quantidade
		// 	);
    //
		// 	const menor = this.pedidoNew.produtos.some(
		// 		(v) => v.quantidadedevolver < 0
		// 	);
    //
		// 	if (totalQuantidadeDev === 0 || maior || menor) {
		// 		return true;
		// 	}
    //
		// 	return false;
		// },
		async init() {
			await this.get();
			await this.listaProcessos();
			await this.listaMovimentacoes();
      await this.listaProdutos();
      await this.listaFinanceiro();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>